// **********************************************************
// LAYOUT
.beamLines {
  &__img {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    &__bg {
      width: 100%;
      height: auto;
    }
    &__names {
      position: absolute;
      left: 0; width: 100%;
      top: 0; height: 100%;
      z-index: $zindex-flat;
    }
  }
  &__cardList {
    padding-bottom: 2rem;
  }
  &__nameList {
    display: none;
    li {
      margin-bottom: 0.5rem;
    }
    &__link {
      font-weight: $font-weight-bold;
      color: var(--#{$prefix}primary);
      text-decoration: none;
      font-size: $h5-font-size;
    }
  }

  // 768 pixels - 48em
  @include media-breakpoint-up(md) {
    &__cardList {
      &, &.ui.cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem 1rem;
      }
    }
  }

  // 992 pixels - 62em
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1.5rem;
    align-items: start;
    &__title {
      grid-column: 1 / -1;
    }    
    &__nameList {
      display: block;
      grid-column: 1 / 4;
      padding-left: 1em;
    }
    &__wrapper {
      grid-column: 4 / 13;
      position: relative;
    }
    &__cardList {
      &, &.ui.cards {
        display: block;
        padding: 0;
      }
    }
  }

  // 1600 pixels - 100em
  @include media-breakpoint-up(xxl) {
    &__nameList {
      grid-column: 1/ 4;
      padding-left: 3em;
    }
    &__wrapper {
      grid-column: 5 / 13; 
    }
  }

  
}

// **********************************************************
// BEAMLINE ITEM
.beamLinesItem {
  &.ui.card {
    border-radius: 0 !important;
    background-color: var(--#{$prefix}light);    
    position: relative;
    margin-bottom: 2rem;
    border: none;
  }
  .content {
    border-radius: 0 !important;
  }
  &__title {
    &, .ui.cards > .card > .content > &.header {
      font-size: $h6-font-size;
      margin-bottom: 0;
    }
  }
  &__link {
    text-decoration: none;
    color: var(--#{$prefix}primary);
  }
  &__subTitle {
    &, &.meta {
      font-size: $font-size-sm !important;
      color: var(--#{$prefix}primary) !important;
      font-weight: $font-weight-normal;
      margin: 0;
    }
  }
  &__desc {
    &, .description {
      font-size: $font-size-sm;
      opacity: 1;
      color:rgba(var(--#{$prefix}body-color-rgb), 0.88) !important;
    }
  }
  .squareMore {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 1rem;
    transform: translateY(50%);
  }

  // 768 pixels - 48em
  @include media-breakpoint-up(md) {
    &.ui.card {
      margin-bottom: 0;
    }
  }

  // 992 pixels - 62em
  @include media-breakpoint-up(lg) {
    &.ui.card {
      display: none;
      position: absolute;
      width: 17rem;
      z-index: $zindex-dropdown;
      &.show {
        display: flex;
      }
    }
  }
}

// **********************************************************
// BEAMLINES MAP
.beamLineName {
  cursor: pointer;
}

// **********************************************************
// BEAMLINES GRAPH
.blGraph {
  position: relative;
  width: 100%; 
  margin: 1.5rem 0;
  &__dividers {
    display: flex;
    justify-content: space-between;
    & > span {
      flex: 0 0 1px;
      width: 1px;
      background-color: var(--#{$prefix}light);  
      height: calc((var(--#{$prefix}beams-number, 14) + 1) * 2rem);
    }
  }
  &__ruler {
    border-top: 1px solid var(--#{$prefix}primary);
    display: flex;
    justify-content: space-between;
    & > span {
      flex: 0 0 1px;
      width: 1px;
      height: 4px;
      background-color: var(--#{$prefix}dark-primary);
    }
  }
  &__values {
    padding-top: 12px;
    font-size: $font-size-sm;
    color: var(--#{$prefix}primary);
    font-family: $headings-font-family;
    display: flex;
    justify-content: space-between;
    & > span {
      position: relative;
      transform: translateX(-50%);
      &:first-child, &:last-child {
        transform: none;
      }
    }
  }
  &__lines {
    a {
      text-decoration: none;
      background-color: var(--#{$prefix}secondary);
      color: var(--#{$prefix}primary);
      display: block;
      line-height: 1;
      height: 1.5rem;
      font-size: .75rem;
      border-radius: .25rem;
      padding: .25rem .5rem;
      position: absolute;
      z-index: $zindex-flat + 2;
      left: calc( var(--#{$prefix}bl-start) / 70000 * 100% );
      width: calc( (var(--#{$prefix}bl-end) - var(--#{$prefix}bl-start)) / 70000 * 100% );
      &:hover, &:focus {
        background-color: rgba(var(--#{$prefix}secondary-rgb) , .66);
        color: var(--#{$prefix}primary);
      }
      @for $i from 0 through 16 {
        &:nth-child(#{$i + 1}) {
          top: calc((2rem * #{$i}) + .25rem);
        }
      }
    }
    &__name {
      display: block;
      white-space: nowrap;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: .25rem;
    }
  }
}

.beamlineAccordion {
  & > span {
    display: block;
    background-color: var(--#{$prefix}light);
    padding: 0.25rem .5rem;
    position: relative;
    &::after {
      content: '';      
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.13008 12.1312C8.50534 12.7559 7.49077 12.7559 6.86603 12.1312L0.468693 5.73386C0.00888521 5.27405 -0.126059 4.58934 0.123837 3.98959C0.373733 3.38984 0.953491 3 1.60322 3H14.3979C15.0426 3 15.6274 3.38984 15.8773 3.98959C16.1272 4.58934 15.9872 5.27405 15.5324 5.73386L9.13508 12.1312H9.13008Z' fill='%23777777'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat; 
      background-position: center center;   
      background-size: .625rem; 
      display: block;
      width: 1rem;
      height: 1rem;
      position: absolute;
      right: 1rem;
      top: .5rem;
      transition: $transition-base;
      transform-origin: center center;
    }
  }
  & > ul {
    transition: max-height 1s;
    max-height: 0;
    overflow: hidden;
  }
  &:hover, &:focus-within {
    & > span::after {
      transform: rotate(180deg);
    }
    & > ul {
      max-height: calc( var(--#{$prefix}lines-number, 5) * 2rem );
    }
  }
}