// Code to fix Semantic UI behaviours


// **********************************************************
// BUTTONS
.ui.button {
	font-size: calc(var(--#{$prefix}btn-font-size) * var(--#{$prefix}fs-scale));
	font-weight: $font-weight-bold;
	font-family: $font-family-sans-serif;
	border-radius: 1.5rem;
	line-height: 1.5;
	&:focus {
		outline: var(--#{$prefix}primary) 1px dotted;
	}
} 
.ui.inverted.primary.button {
	box-shadow: 0 0 0 2px var(--#{$prefix}primary) inset !important;
	color: var(--#{$prefix}primary);
	&:hover, &:focus {
		box-shadow: none !important;
		background-color: var(--#{$prefix}primary);
	}
}

.ui.inverted.secondary.buttons .button, .ui.inverted.secondary.button {
	color:#6d767a;
}

.ui.primary.buttons .button, .ui.primary.button {
	&:hover, &:focus {
		background-color: var(--#{$prefix}secondary);
		color: var(--#{$prefix}primary);
	}
}

// **********************************************************
// CONTAINER
body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .ui.container:not(.fluid) {
	// 1600 pixels - 100em
	@include media-breakpoint-up(xxl) {
		width: map-get($map: $container-max-widths, $key: 'xxl') !important;
	}
	 
}

body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .ui.container:not(.fluid) {
	@include media-breakpoint-up(xxl) {
		width: calc(map-get($map: $container-max-widths, $key: 'xxl') - 80px)  !important;
	}
	 
}
body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .ui.container.fluid,
body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .ui.container.fluid {
	// 768 pixels - 48em
	@include media-breakpoint-up(md) {
		width: calc( 100% - 2em)  !important;
		
	}
}
body.hasRelatedContent .content-area.ui.segment {
	margin-bottom: 0;
}

// **********************************************************
// DROPDOWN
.ui.dropdown > .dropdown.icon {
	font-size: 1em;
}
.ui.menu .dropdown.item .menu {
	background-color: var(--#{$prefix}bgMenu);
}
.ui.dropdown .menu > .item { display: flex; justify-content: space-between;
	.text { flex-shrink: 1;}
	.icon { flex: 0 0 auto;}
}


// **********************************************************
// FORMS 
.ui.form input:not([type]), .ui.form input[type="date"], 
.ui.form input[type="datetime-local"], 
.ui.form input[type="email"], 
.ui.form input[type="number"], 
.ui.form input[type="password"], 
.ui.form input[type="search"], 
.ui.form input[type="tel"], 
.ui.form input[type="time"], 
.ui.form input[type="text"], 
.ui.form input[type="file"], 
.ui.form input[type="url"] {
	color: var(--#{$prefix}body-color);
	font-size: calc(1em * var(--#{$prefix}fs-scale));
}
.ui.form .field > label {
	color: var(--#{$prefix}body-color);
}
.ui.labels .label, .ui.label {
	font-size: calc(0.75rem * var(--#{$prefix}fs-scale));
}

// **********************************************************
// TOOLBAR
.toolbar-content, .controlpanel {
	a {
		text-decoration: none;
	}
}
#toolbar .pastanaga-menu-list li a, #toolbar .pastanaga-menu-list li button {	
	font-family: $font-family-body;
  }

  
// **********************************************************
// CONTROL PANEL
.controlpanel {
	a {
		text-decoration: none;
	}
	h3.ui.header {
		font-size: 1.125rem;
	}
}
   
.no-mainFlex #main { display: block;}

// **********************************************************
// SEGMENT
.ui.segment {
	font-size: calc( var(--#{$prefix}body-font-size) * var(--#{$prefix}fs-scale) );
	&.secondary {
		font-size: calc( 0.75rem * var(--#{$prefix}fs-scale) );

	}
}

// **********************************************************
// FONT SIZES
h1 { font-size:	calc( #{$h1-font-size} * var(--#{$prefix}fs-scale) ) };
h2 { font-size:	calc( #{$h2-font-size} * var(--#{$prefix}fs-scale) ) };
h3 { font-size:	calc( #{$h3-font-size} * var(--#{$prefix}fs-scale) ) };
h6 { font-size:	calc( #{$h6-font-size} * var(--#{$prefix}fs-scale) ) };
h4 { font-size:	calc( #{$h4-font-size} * var(--#{$prefix}fs-scale) ) };
h5 { font-size:	calc( #{$h5-font-size} * var(--#{$prefix}fs-scale) ) };

.ui.header {
	&.huge { font-size: calc( 2.5em * var(--#{$prefix}fs-scale));}
	&.large { font-size: calc( 2em * var(--#{$prefix}fs-scale));}
	&.medium { font-size: calc( 1.75em * var(--#{$prefix}fs-scale));}
	&.small { font-size: calc( 1.5em * var(--#{$prefix}fs-scale));}
	&.small { font-size: calc( 1.125em * var(--#{$prefix}fs-scale));}
}

@include media-breakpoint-up(xxxl) {
	body {
		--#{$prefix}fs-scale: 1.125;
	}
}

// **********************************************************
// TABLES
@media only screen and (max-width: 767px) {
	.ui.table:not(.unstackable) {
		thead {
			display: none !important;
		}
		td::before {
			content: attr(data-caption);
			display: block;
			font-size: $font-size-xs;
			text-transform: uppercase;
			font-weight: $font-weight-normal;
			color: var(--#{$prefix}primary);
			margin-top: 0.5em;
		}
		td:empty::before {
			content: none;
		}
	}
}